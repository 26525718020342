<template>
  <header class="flex gap-20 justify-between md:py-7 lg:py-10 px-4 lg:px-12 items-center">
    <NuxtLink to="/" class="m-2 lg:p-0">
      <WellLogo
        class="max-h-[67px] max-md:max-w-full"
      />
    </NuxtLink>
    <nav class="gap-4 self-end text-dark-gray hidden lg:flex">
      <NuxtLink class="whitespace-nowrap" to="/experiences">Experiences</NuxtLink>
      <a class="whitespace-nowrap" target="_blank" href="https://wellwellwell.blog/">Blog</a>
      <span
        class="whitespace-nowrap cursor-pointer"
        @click="openLoginModal"
        v-if="!userStore.isLoggedIn"
      >
        Login
      </span>
      <span
        class="whitespace-nowrap cursor-pointer"
        @click="userStore.logout"
        v-if="userStore.isLoggedIn"
      >
        Logout
      </span>
      <a class="underline whitespace-nowrap" target="_blank" href="https://heymarvelous.com/well-well-well">
        Host Your Own
      </a>
    </nav>
    <div class="flex-1 lg:hidden flex justify-end">
      <!-- <WellIcon
        icon="menu-03"
        class="text-dark"
        @click="toggleMenu"
        :size="24"
      /> -->
    </div>
    <Drawer
      id="mobile-menu"
      v-model:visible="isMobileMenuOpen"
      position="right"
    >
      <ul
        class="flex flex-col text-primary-contrast font-serif text-3xl"
        @click="isMobileMenuOpen = false"
      >
        <li class="border-b border-primary-contrast">
          <NuxtLink
            class="py-5 block"
            to="/experiences"
          >
            Experiences
          </NuxtLink>
        </li>
        <li class="border-b border-primary-contrast">
          <a class="py-5 block" target="_blank" href="https://wellwellwell.blog/">Blog</a>
        </li>
        <li class="border-b border-primary-contrast">
          <span
            class="py-5 block"
            @click="openLoginModal"
            v-if="!userStore.isLoggedIn"
          >
            Login
          </span>
          <span
            class="py-5 block"
            @click="userStore.logout"
            v-if="userStore.isLoggedIn"
          >
            Logout
          </span>
        </li>
        <li class="border-b border-primary-contrast">
          <a target="_blank" href="https://heymarvelous.com/well-well-well"class="py-5 block">Host Your Own</a>
        </li>
      </ul>
    </Drawer>
  </header>
</template>

<script setup lang="ts">
import WellIcon from '~/components/common/WellIcon.vue'
import WellLogo from '~/components/common/WellLogo.vue'
import LoginModal from '~/components/auth/LoginModal.vue'
import { useModal } from '@/composables/useModal'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const modal = useModal();
const isMobileMenuOpen = ref(false)

const toggleMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value
}

const openLoginModal = () => {
  modal.show(LoginModal, {})
}
</script>


<style lang="scss">
#mobile-menu {
  --p-drawer-background: var(--dark);
  --p-button-text-secondary-color: var(--primary-contrast);
  html .p-dialog-header,
  .p-drawer-header {
    justify-content: flex-end;
  }
}
</style>
